import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { tenantEnvConfig } from '@/constants/tenantConfig/tenantEnvConfig';
import { LineClampStyled } from '@/shared/features/landingpage/styles/line-clamp';

import * as display from '../constants';
import RemoveFavorite from './RemoveFavorite';
import { getConfigByKey } from '@/shared/features/tenantConfig/services';

const displayTitle = getConfigByKey('features.displayFooterContentOfContinueWatchingFeature');

const Regions = ({ listRegions, language }) => {
  return listRegions.map((items, index) => {
    return (
      <React.Fragment key={index}>
        {!index && ' | '}
        <Link to={`/nationalities/${items.slug}`}>{items.name} </Link>
        <span>{index < listRegions.length - 1 ? ', ' : null}</span>
      </React.Fragment>
    );
  });
};

const FooterContent = React.memo(props => {
  const { t } = useTranslation();
  const { title, ribbon, handleOpen, item, remove, language } = props;
  const isContinueWatching = ribbon?.type === display.TYPE_RECENT_MOVIE;

  const subTitle = () => {
    if (!isContinueWatching) {
      return null;
    }

    const releaseYear = item.release_date
      ? moment(item.release_date, 'YYYY-MM-DD').format(`YYYY`)
      : null;
    const totalEps = item.total_episodes;
    const listRegions = (item.metadata && item.metadata.regions) || [];
    if (item.type === display.MOVIE_TYPE || item.type === display.CLIP) {
      return (
        <div className="ribbonItemContainer--subTille">
          {releaseYear}{' '}
          {listRegions && listRegions.length ? (
            <Regions listRegions={listRegions} language={language} />
          ) : null}
        </div>
      );
    }
    if (item.type === display.SHOW_TYPE && totalEps) {
      return (
        <div className="ribbonItemContainer--subTille" style={{ textTransform: 'lowercase' }}>
          {totalEps} {t('video.eps')}
        </div>
      );
    }
    return null;
  };

  const RemoveButton = () => {
    if (remove) return <RemoveFavorite remove={remove} {...props} />;
    if (ribbon && ribbon.type === display.TYPE_RECENT_MOVIE) {
      return (
        <Button onClick={handleOpen} className="thien">
          <i className="fa fa-trash" />
        </Button>
      );
    }
    return null;
  };

  return (
    <div
      className={classnames(
        'ribbonItemContainer-icon-remove',
        remove && 'ribbonItemContainer-icon-remove__favorite',
      )}
    >
      {displayTitle && (
        <div className="content-title">
          {isContinueWatching && (
            <LineClampStyled className="texttruncate line-clamp-2">{title}</LineClampStyled>
          )}
          {subTitle()}
        </div>
      )}

      <RemoveButton />
    </div>
  );
});

export default FooterContent;
