import * as types from './actionTypes';

export function getMenu(menu) {
  return {
    type: types.GET_MENU,
    menu,
  };
}

export function getPageRequest() {
  return {
    type: types.GET_PAGE_REQUEST,
  };
}

export function getPage(page) {
  return {
    type: types.GET_PAGE,
    page,
  };
}

export function getDetail(detail) {
  return {
    type: types.GET_DETAIL,
    detail,
  };
}

export function refreshPage(isRefreshPage) {
  return {
    type: types.REFRESH_PAGE,
    isRefreshPage,
  };
}

export function getRecent(payload) {
  return {
    type: types.GET_RECENT,
    payload,
  };
}

export function getFinal() {
  return {
    type: types.GET_FINAL,
  };
}

export function updateRibbonTarget(value) {
  return {
    type: types.GET_RIBBON_TARGET,
    idRibbonTarget: value,
  };
}

export function updateStatusRemove(payload) {
  return {
    type: types.UPDATE_STATUS_REMOVE_PROCESS,
    payload,
  };
}

export function updateSeach(queryParams) {
  return {
    type: types.UPDATE_SEARCH,
    queryParams,
  };
}

export function updateSlugPage(payload) {
  return {
    type: types.UPDATE_PAGE_SLUG,
    payload,
  };
}

export function setExtraBanners(payload) {
  return {
    type: types.SET_EXTRA_BANNERS,
    payload,
  };
}
