import { getAgeContentRating } from '@/core/method/contentMethod';
import React from 'react';
import { styled } from 'twin.macro';

const AgeRatingBadgeStyled = styled.div`
  display: inline-block;
  border-radius: 2px;
  flex-shrink: 0;
  .age-badge {
    width: 100%;
    max-width: 100%;
    width: 17px;
    height: 17px;
  }

  &.mr {
    margin-right: 6px;
  }
  &.medium {
    .age-badge {
      width: 20px;
      height: 20px;
    }
  }
  &.big {
    .age-badge {
      width: 40px;
      height: 40px;
    }
  }
`;
const AgeRatingBadge = ({ content, className = '' }) => {
  const ageRating = getAgeContentRating.call(content);
  if (!ageRating) {
    return null;
  }
  return (
    <AgeRatingBadgeStyled className={`age-rating-badge ${className}`}>
      <img className="age-badge" src={ageRating.image_url} />
    </AgeRatingBadgeStyled>
  );
};

export default AgeRatingBadge;
