import clsx from 'clsx';
import React from 'react';
import { styled } from 'twin.macro';
import { ALL_LIVE_TYPE, LIVE_EVENT, TYPE_NO_LANDING_PAGE } from '@/core/constants/contentConstants';
import { activeLiveEvent, needUpgrade, renderBadge } from '@/core/method/contentMethod';

const LiveBadgeStyled = styled.div`
  font-weight: 700;
  font-size: 10px;
  padding: 1px 8px;
  text-transform: uppercase;
  color: #ff0000;
  background: #fff;
  border-radius: 20px;
  flex-shrink: 0;
  margin: 0;
  &.live-event:not(.active-live-event) {
    color: #696969;
  }
  .fa {
    padding-right: 5px;
  }
  &.medium {
    font-size: 13px;
    padding: 5px;
  }
  &.large {
    font-size: 14px;
    padding: 0px 14px 0px 8px;
  }
`;
const CONTENT_PREMIERE = 'content-premiere';
const CONTENT_BADGES_STRING = {
  [CONTENT_PREMIERE]: 'premiere',
};

export const getBadgeLiveString = content => {
  try {
    const { badges = [], type } = content;
    if (badges.includes(CONTENT_PREMIERE)) {
      return CONTENT_BADGES_STRING[CONTENT_PREMIERE] ?? '';
    }
    if (ALL_LIVE_TYPE.includes(type)) {
      return 'Live';
    }
    return '';
  } catch (error) {
    return '';
  }
};

const LiveBadge = ({ content, size = 'small', className }) => {
  return (
    <LiveBadgeStyled
      className={clsx('ribbon-ticket-live', size, className, {
        'live-event': content?.type === LIVE_EVENT,
        'active-live-event': activeLiveEvent.call(content),
      })}
    >
      <span className="live-icon">
        <i className="fa fa-circle" aria-hidden="true" />
        {getBadgeLiveString(content) || 'Premiere'}
      </span>
    </LiveBadgeStyled>
  );
};

export default LiveBadge;
