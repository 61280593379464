import FETCH from '../utils/security/fetch';
import * as APIString from '../constants/apiString';
import { store } from '@/store/store';
import queryString from 'query-string';
import { getDeviceResolution } from '@/shared/utils/utils';
import { appConfig } from '../common/config';

const CONTENT_RIBBON_DETAIL_SELECT_FIELDS = [
  'display_type',
  'id',
  'name',
  'type',
  'items',
  'min_sub_tier',
  'seo',
];

const CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS = [
  'category_slug',
  'content_categories',
  'images',
  'is_new_release',
  'is_premium',
  'has_free_content',
  'id',
  'slug',
  'released_episode_count',
  'title',
  'top_index',
  'type',
  'video_source',
  'min_sub_tier',
  'metadata',
  'start_on',
  'duration',
  'unpublished_time',
  'badges',
  'release_date',
  'payment_type',
];

const CONTENT_FAVORITE_SELECT_FIELDS = [
  'type',
  'released_episode_count',
  'total_episodes',
  'video_source',
  'images',
  'id',
  'slug',
  'title',
  'release_date',
];

const CONTENT_SEARCH_YOUTUBE_PAGE = ['items', 'suggestion_items', 'keyword'];
const SEARCH_PAGE_CONTENT_ITEMS_SELECT_FIELDS = [
  'can_preview',
  'content_categories',
  'has_free_content',
  'id',
  'images',
  'is_new_release',
  'is_premium',
  'is_watchable',
  'num_first_episode_preview',
  'slug',
  'title',
  'type',
  'video_source',
  'min_sub_tier',
];
function getRibbonDetail(pageSlug, ribbonSlug, search = '') {
  const { tenantSlug, authorization } = appConfig;

  let Authorization = '';
  const select = JSON.stringify({
    RibbonDetailPaging: CONTENT_RIBBON_DETAIL_SELECT_FIELDS,
    Content: CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS,
  });
  let data = { ...queryString.parse(search), ...getDeviceResolution(), select };

  const queryParams = queryString.stringify(data);
  if (authorization) {
    Authorization = authorization;
  }
  const path = `/tenants/${tenantSlug}/tenant_pages/${pageSlug}/ribbons/${ribbonSlug}/?${queryParams}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function searchYoutubePage(params, pageSlug) {
  const select = JSON.stringify({
    Search: CONTENT_SEARCH_YOUTUBE_PAGE,
    YOUTUBE: SEARCH_PAGE_CONTENT_ITEMS_SELECT_FIELDS,
  });
  let data = { ...params, ...getDeviceResolution() };
  const queryParams = queryString.stringify({ ...data, select });
  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_page/${pageSlug}/search_contents/?q=${params.keyword}&${queryParams}`;
  return FETCH(path, 'GET', {});
}

function getRibbonSearchContent(pageSlug, search = null) {
  const { account } = store.getState().auth;
  let Authorization = '';
  const data = { ...queryString.parse(search), ...getDeviceResolution() };
  const queryParams = queryString.stringify(data);
  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_pages/${pageSlug}/tenant_page/?${queryParams}`;

  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }

  return FETCH(path, 'GET', {
    Authorization,
  });
}

function getRibbonDetailRecent(pageSlug, ribbonSlug, search = null) {
  const { account } = store.getState().auth;
  let Authorization = '';
  const select = JSON.stringify({
    RibbonDetailPaging: CONTENT_RIBBON_DETAIL_SELECT_FIELDS,
    Content: CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS,
  });
  let data = { ...queryString.parse(search), ...getDeviceResolution(), select };
  const queryParams = queryString.stringify(data);
  if (account) {
    const accountId = account.profile.id;
    Authorization = account.access_token ? account.access_token : '';
    const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/tenant_pages/${pageSlug}/personalized_ribbons/${ribbonSlug}/?${queryParams}`;
    return FETCH(path, 'GET', {
      Authorization,
    });
  }
}

function deleteProgress(contentId) {
  const { account } = store.getState().auth;
  let Authorization = null;
  if (account) {
    Authorization = account.access_token ? account.access_token : null;
  }
  if (!Authorization) {
    return;
  }
  const path = `/backend/cm/content/${contentId}/progress`;
  return FETCH(path, 'DELETE', {
    Authorization,
    'Content-Type': 'application/json',
  });
}

function getTopTen(pageSlug) {
  const { account } = store.getState().auth;
  let Authorization = null;
  let param = '';
  const select = JSON.stringify({
    Content: CONTENT_FAVORITE_SELECT_FIELDS,
  });
  let data = { ...getDeviceResolution(), select };

  const queryParams = queryString.stringify(data);
  if (pageSlug) {
    param = `tenant_page_slug=${pageSlug}`;
  }
  if (account) {
    Authorization = account.access_token ? account.access_token : null;
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/top_contents/?${queryParams}&${param}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

export function getStaticImages() {
  const path = `/tenants/${APIString.TENANT_SLUG}/static_images/`;
  return FETCH(path, 'GET');
}

export {
  getRibbonDetail,
  deleteProgress,
  getRibbonDetailRecent,
  getTopTen,
  getRibbonSearchContent,
  searchYoutubePage,
};
