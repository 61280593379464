import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as display from '../constants';
import { contentTypes } from '../../../utils/utils';
import { updateRibbonTarget } from '../../../features/homepage/services';
import Contents from './contents';
import { TYPE_NO_LANDING_PAGE } from '@/core/constants/contentConstants';

class RibbonItem extends React.Component {
  render() {
    let {
      index,
      item,
      ribbon,
      linkDetail,
      handleOpen,
      remove,
      imgDefault,
      name,
      blockUrl,
      trailer,
      epNameVisible,
      dispatchPostFavorite,
      lazyLoad,
      payperview,
      handleClick,
    } = this.props;
    let { title, slug, type, images } = item;
    let displayType = ribbon && ribbon.display_type;
    const displayTypeStr = display[`CLASS_${displayType || 1}`];
    let sourceURL = images;

    if (!sourceURL) {
      sourceURL = images.thumbnail || images.poster || images.banner || '';
    }
    if (name) {
      title = name;
    }
    let linkTo =
      TYPE_NO_LANDING_PAGE.includes(type) || item.video_source === 1 || linkDetail
        ? `/detail/${slug}`
        : `/landing/${slug}`;

    if (blockUrl) {
      linkTo = null;
    }
    if (item.video_source === 1 && type === display.SHOW_TYPE && !linkDetail) {
      linkTo = `/landing/${slug}`;
    }
    if (trailer && ribbon) {
      linkTo = `/detail/${ribbon.slug}`;
    }

    let contentType = contentTypes(item);
    return (
      <Contents
        linkTo={linkTo}
        item={item}
        ribbon={ribbon}
        index={index}
        displayTypeStr={displayTypeStr}
        contentType={contentType}
        type={type}
        dpUpdateRibbonTarget
        handleOpen={handleOpen}
        title={title}
        sourceURL={sourceURL}
        lazyLoad={lazyLoad}
        remove={remove}
        imgDefault={imgDefault}
        trailer={trailer}
        blockUrl={blockUrl}
        epNameVisible={epNameVisible}
        dispatchPostFavorite={dispatchPostFavorite}
        payperview={payperview}
        handleClick={handleClick}
      />
    );
  }
}

RibbonItem.defaultProps = {
  title: '',
  sourceURL: '',
  viewNumber: 0,
  favoritesNumber: 0,
  slug: '',
  type: 0,
};

const mapStateToProps = state => ({
  account: state.auth.account,
});

const mapDispatchToProps = {
  dpUpdateRibbonTarget: id => updateRibbonTarget(id),
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RibbonItem));
