import { styled } from 'twin.macro';

const Ribbons = styled.div`
  &.ribbonContainer {
    /* width: 100%;
    display: block; */
    /* float: left; */
    /* overflow: hidden; */
    background-color: inherit;
    font-size: 16px;
    margin-bottom: 30px;
    /* padding-right: var(--plr); */
    .ribbonHead {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      margin-bottom: 8px;
      align-items: center;
      h4.title {
        font-family: Roboto, sans-serif;
        font-size: 1.25rem;
        font-weight: bold;
        color: rgb(255, 255, 255);
        padding-left: 0px;
        text-transform: uppercase;
        max-width: 78%;
        z-index: 11;
        line-height: normal;
        margin: 0;
      }
      h5.title_action {
        font-family: Roboto, sans-serif;
        font-size: 1.25rem;
        font-weight: 200;
        color: rgb(231, 45, 48);
        /* padding-right: 30px; */
        cursor: pointer;
        white-space: nowrap;
        i {
          color: rgb(231, 45, 48);
          font-size: 1rem;
        }
      }
      a.viewAll {
        font-size: calc(1.8rem * var(--multiplierFontSize));
        color: #0095ff;
        padding-right: 0;
        margin-right: 0;
        float: right;
        text-decoration: none;
        z-index: 11;
      }
    }
    .see-more {
      .btn-see-more {
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
        background: #8f8f8f;
        color: #dedede;
        text-transform: capitalize;
        transform: scale(1) !important;
        border: none;
        p {
          font-size: 1.5rem;
        }
        &:hover {
          background: #2574d4;
          color: #dedede;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .prevButton {
      left: 0;
      background: linear-gradient(90deg, rgba(17, 18, 18, 0.7) 30%, rgba(17, 18, 18, 0));
    }
    .nextButton {
      background: linear-gradient(270deg, rgba(17, 18, 18, 0.7) 30%, rgba(17, 18, 18, 0));
      right: 0;
    }
    .customiseSlickBtn {
      height: calc(100% - 24px) !important;
    }
    .prevButton,
    .nextButton {
      width: 30px;
      visibility: visible;
      position: absolute;
      z-index: 9;
      top: 0;
      cursor: pointer;
      text-decoration: none;
      opacity: 1;
      transition:
        visibility 1s linear 0.33s,
        opacity 0.33s linear;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .prevButtonIcon,
      .nextButtonIcon {
        transition: font-size 0.5s ease-out 100ms;
        font-size: 1.85rem;
        color: #ffffff;
      }
    }
  }

  .sliderBlock {
    /* clear: both;
    width: 100%; */
    /* margin-left: -8px; */
  }

  .icon_chevron_right {
    font-size: 16px;
    color: #3e3e3e;
    margin-left: 8px;
  }

  &.ribbonItemContainer {
    /* margin-right: calc(1.6rem * var(--multiplierFontSize)); */
    overflow: hidden;
    font-size: calc(1.8rem * var(--multiplierFontSize));
    .loading-placeholder {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      span {
        width: 100%;
        height: 100%;
        display: flex;
      }
    }
    a {
      color: #444444;
      display: block;
      text-decoration: none;
    }
    &:hover {
      a .srcImg {
        transform: scale(1.1);
        overflow: hidden;
        -webkit-box-shadow: 0px 0px 19px -3px rgba(71, 71, 71, 1);
        -moz-box-shadow: 0px 0px 19px -3px rgba(71, 71, 71, 1);
        box-shadow: 0px 0px 19px -3px rgba(71, 71, 71, 1);
      }
      .description .text {
        display: block;
      }
      button.remove {
        opacity: 1;
        background-color: rgba(189, 2, 2, 0.8);
        z-index: 9;
        color: #fff;
      }
    }
    .description {
      display: block;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .text {
        clear: both;
        padding: 0px 0 14px;
        overflow: hidden;
        display: none;
      }
      .icon_play {
        margin-right: 10px;
        color: #959596;
      }
      .play {
        float: left;
        font-size: 14px;
        height: 16px;
        line-height: 16px;

        font-weight: 400;
      }
      .heart {
        font-size: 14px;
        float: right;
        height: 16px;
        line-height: 16px;
        /* margin-left: 3%; */

        font-weight: 400;
      }
      span.subTitle {
        color: #959596;
        font-size: 13px;
      }
    }
    h6 {
      font-size: 14px;
      /* padding: 14px 0; */

      font-weight: bold;
      color: ${({ theme }) => theme.ribbon.itemNameColor};
    }
    .imageWrapper {
      width: 100%;
      padding: 0;
      border: none;
      overflow: hidden;
      border-radius: 6px;
      position: relative;
      padding-top: calc(9 / 16 * 100%);
      z-index: 1;
      &.thumbnail {
        background-color: unset;
        margin-bottom: 0;
      }
      &.poster {
        padding-top: calc(40 / 27 * 100%);
      }
      .srcImg {
        display: block;
        width: 100%;
        transition: all 200ms ease-out;
        transform: scale(1);
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: top;
        &--noImg {
          transform: scale(2);
        }
      }
    }
    .remove {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      text-align: center;
      opacity: 0;
      border: none;
      background: none;
      transition: opacity 0.35s ease;
    }
    .ribbonItemContainer-icon-remove {
      &__favorite {
        .btnRemove {
          font-size: 24px;
          color: #fff;
          cursor: pointer;
          border-radius: 5px;
          padding: 5px;
          display: flex;
          width: 35px;
          height: 35px;
          &:hover {
            background: #bd0202;
          }
        }
      }
      display: flex;
      justify-content: space-between;
      /* max-height: calc(4rem * var(--multiplierFontSize)); */
      font-size: calc(1.6rem * var(--multiplierFontSize));
      font-weight: 500;
      color: #333333;
      button {
        background: #000000;
        color: #fff;
        width: calc(2.8rem * var(--multiplierFontSize));
        max-height: calc(2.4rem * var(--multiplierFontSize));
        display: flex;
        justify-content: center;
        align-items: center;
        border: #000000;
        padding: 5px;
        font-size: calc(1.6rem * var(--multiplierFontSize));
        border-radius: 15px;
        i {
          color: #627280;
          font-size: 14;
          padding-right: 'calc(1.4rem * var(--multiplierFontSize))';
        }
        &:hover {
          background: #bd0202;
          border: #bd0202;
          color: #fff;
          border-radius: 5px;
          i {
            color: #fff;
          }
        }
        &:focus {
          background: #000000;
          border: #000000;
          color: #fff;
          outline: none;
          i {
            color: #fff;
          }
        }
      }
    }
    &.small {
      /* width: 90%; */
    }
  }
  .ribbonItemContainer {
    &--subTille {
      font-size: 14px;
      color: #9b9b9b;
      display: flex;
      flex-wrap: wrap;
      a {
        font-size: 14px;
        color: #9b9b9b;
        padding-left: 6px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &.ribbonItemContainer {
    .ribbonTicketTopContent {
      position: absolute;
      right: 0;
      width: 28%;
      height: 100%;
      bottom: 0;
      img {
        min-height: 0;
        width: 100%;
        height: 100%;
        transform: scale(1) !important;
        &:hover {
          transform: scale(1);
        }
      }
    }
    .poster .ribbonTicketTopContent {
      width: 38%;
      height: 50%;
    }
    .ribbonTicketYotube {
      position: absolute;
      left: calc(1.2rem * var(--multiplierFontSize));
      width: calc(4.5rem * var(--multiplierFontSize));
      bottom: calc(1.2rem * var(--multiplierFontSize));
      img {
        min-height: 0;
        width: 100%;
        height: auto;
        transform: scale(1) !important;
        &:hover {
          transform: scale(1);
        }
      }
    }
    .ribbonTicket {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      top: 3%;
      right: 3%;
      /* right: calc(1.2rem * var(--multiplierFontSize)); */
      > div:not(:last-child) {
        margin-right: 10px;
      }
      width: 100%;
      /* flex-direction: row-reverse; */
      .ribbonTicketLive {
        font-weight: 700;
        font-size: 10px;
        padding: 4px 8px;
        text-transform: uppercase;
        color: #ff0000;
        background: #fff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        --fm-capitalHeight: 0.68;
        --fm-descender: 0;
        --fm-ascender: 0;
        --fm-linegap: 0;
        --capital-height: 7;
        --computedFontSize: (var(--capital-height) / var(--fm-capitalHeight));
        --lineheightNormal: (var(--fm-ascender) + var(--fm-descender) + var(--fm-linegap));
        --contentArea: (var(--lineheightNormal) * var(--computedFontSize));
        --distanceBottom: (var(--fm-descender));
        --distanceTop: (var(--fm-ascender) - var(--fm-capitalHeight));
        --valign: ((var(--distanceBottom) - var(--distanceTop)) * var(--computedFontSize));
        --line-height: 2;
        .fa {
          line-height: calc(((var(--line-height) * var(--capital-height)) - var(--valign)) * 1px);
          font-size: calc(var(--computedFontSize) * 1px);
          vertical-align: calc(var(--valign) * -1px);
          display: flex;
          align-items: center;
          margin-right: 6px;
        }
        span {
          line-height: calc(((var(--line-height) * var(--capital-height)) - var(--valign)) * 1px);
          font-size: calc(var(--computedFontSize) * 1px);
          vertical-align: calc(var(--valign) * -1px);
        }
        &.live-event:not(.active-live-event) {
          color: #696969;
        }
      }
      .ribbonTicketVip {
        background-color: rgba(0, 0, 0, 0.7);
        position: relative;
        font-weight: bolder;
        font-size: 10px;
        padding: 2px 7px;
        color: rgb(232, 182, 0);
        border-radius: 5px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        span {
          line-height: 1px;
        }
      }
    }

    .ribbonTicketVip {
      font-weight: bolder;
      font-size: calc(1.4rem * var(--multiplierFontSize));
      position: absolute;
      /* top: calc(0.4rem * var(--multiplierFontSize));
      right: calc(0.4rem * var(--multiplierFontSize)); */
      padding: calc(0.2rem * var(--multiplierFontSize));
      color: #fff;
      border-radius: 50%;
      background-color: #e8b600;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        padding: calc(0.2rem * var(--multiplierFontSize));
      }
      /* &.sctv {
        font-size: calc(1.4rem * var(--multiplierFontSize));
        top: 10%;
        right: 0px;
        padding: calc(0.4rem * var(--multiplierFontSize)) calc(1.6rem * var(--multiplierFontSize));
        color: rgb(232, 182, 0);
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.6);
      } */
    }
    .ribbonTicketTvod {
      font-size: 10px;
      position: absolute;
      top: calc(0.4rem * var(--multiplierFontSize));
      right: calc(0.4rem * var(--multiplierFontSize));
      padding: calc(0.2rem * var(--multiplierFontSize));
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .ribbonTicketNoLive {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      top: 2%;
      color: #fff;
      .span {
        text-transform: none;
        font-size: calc(1.2rem * var(--multiplierFontSize));
        font-weight: 100;
        text-align: right;
      }
    }
  }

  &.ribbonContainer .sliderBlock .slick-slider {
    /* z-index: 100; */
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  @media (min-width: 768px) {
    .sliderBlock {
      /* margin-left: -15px; */
    }
    &.ribbonContainer {
      margin-bottom: 42px;

      &:hover {
        .prevButton,
        .nextButton {
          visibility: visible;
          opacity: 1;
          transition-delay: 0s;
          background-color: rgba(69, 69, 69, 0.6);
          text-decoration: none;
        }
      }
      .prevButton,
      .nextButton {
        width: 60px;
        visibility: hidden;
        opacity: 0;
        border-radius: 5px 0 0 5px;
      }
    }
  }
  @media (min-width: 992px) {
    .sliderBlock {
      /* margin-left: -20px; */
    }
    &.ribbonItemContainer {
      .ribbonTicketYotube {
        width: calc(5rem * var(--multiplierFontSize));
      }
    }
    &.ribbonContainer {
      .ribbonHead {
        h4.title {
          font-size: 24px;
        }
      }
    }
  }
`;

export const StyledRibbons = styled(Ribbons)`
  margin-bottom: 40px;
  .slick-track {
    display: flex;
    /* column-gap: 16px; */
    &::before,
    &:after {
      display: none;
    }
  }
  --inlineSpacing: 5px;
  --posterInlineSpacing: 4px;
  .slick-slide {
    padding-inline: var(--inlineSpacing);
  }
  .sliderBlock {
    margin-inline: calc(var(--inlineSpacing) * -1);
  }
  .slider-poster {
    .slick-slide {
      padding-inline: var(--posterInlineSpacing);
    }
    &.sliderBlock {
      margin-inline: calc(var(--posterInlineSpacing) * -1);
    }
  }

  /* [aria-hidden='true'] {
    display: none;
  } */

  /* .ribbonItemContainer {
    width: calc(100% - 20px);
  }
  .slick-active + .slick-active .ribbonItemContainer {
    margin-inline: auto;
  } */

  @media (min-width: 768px) {
    .slick-track {
      /* column-gap: 30px; */
    }
    .slick-slide {
      /* padding-right: 35px; */
    }
  }
  @media (min-width: 992px) {
    margin-bottom: 48px;
    --inlineSpacing: 11px;
    --posterInlineSpacing: 7px;
  }
`;
export default Ribbons;
