import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const RemoveFavorite = React.memo(props => {
  const { index } = props;
  const handleRemove = event => {
    event.stopPropagation();
    props.dispatchPostFavorite(index);
  };
  return (
    <div className="btnRemove" onClick={e => handleRemove(e)}>
      <CloseIcon />
    </div>
  );
});

export default RemoveFavorite;
