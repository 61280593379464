import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { styled } from 'twin.macro';

import { LazyLoadImage as LazyLoad } from 'react-lazy-load-image-component';
import classnames from 'classnames';

import { activeLiveEvent, needUpgrade, renderBadge } from '@/core/method/contentMethod';
import _ from 'lodash';
import { setOpenRequestSignIn } from '@/shared/components/global/globalServices';
import AgeRatingBadge from '@/shared/components/badges/AgeRatingBadge';
import { ALL_LIVE_TYPE, LIVE_EVENT, TYPE_NO_LANDING_PAGE } from '@/core/constants/contentConstants';
import LiveBadge from '@/shared/components/badges/LiveBadge';
import { getTenantConfig } from '../../../utils/utils';
import Ribbons from '../styled';
import { updateRibbonTarget } from '../../../features/homepage/services';
import { getConfigByKey } from '../../../features/tenantConfig/services';
import * as display from '../constants';
import pushAnalytics from '@/services/analytics/Analytics';
import FooterContent from './FooterContent';
import { AccountSubscriptionBadge } from '@/shared/components/badges/AccountSubscriptionBadge';
import MinimumTierBadge from '@/shared/components/badges/MinimumTierBadge';

const Contents = props => {
  const _remove = useCallback((event, index) => {
    event?.stopPropagation();
    props.dispatchPostFavorite(index);
  });

  const _renderVip = useCallback(premium => {
    if (premium) {
      return null;
    }

    return <MinimumTierBadge title={'VIP'} />;
  });

  const _renderLevelBadgeSCTV = useCallback(() => {
    const { paidContents } = props;

    if (
      !needUpgrade.call(item, {
        account,
        paidContents,
      })
    ) {
      return null;
    }

    return (
      <MinimumTierBadge
        title={renderBadge.call({
          entityDetail: item,
          account,
          paidContents,
        })}
      />
    );
  });

  const _displayAccountSubscriptionBadge = useCallback(() => {
    const { paidContents, account } = props;

    if (
      needUpgrade.call(item, {
        account,
        paidContents,
      })
    ) {
      return null;
    }

    return <AccountSubscriptionBadge />;
  });

  const _renderYoutube = useCallback(() => {
    const { item } = props;

    if (item && item.video_source === 1) {
      return (
        <div className="ribbonTicketYotube">
          <img src={require('../../../assets/img/youtube.png')} width="80" height="25" />
        </div>
      );
    }

    return null;
  });

  const _renderTicket = useCallback(() => {
    const { type } = props.item;
    const { account, item, paidContents } = props;

    const flagEnbTier = getConfigByKey('features.multiTierSubscriptionEnabled');
    const premium = !needUpgrade.call(item, {
      account,
      paidContents,
    });
    return (
      <div className="ribbonTicket">
        <AgeRatingBadge content={item} />
        {flagEnbTier ? _renderLevelBadgeSCTV(item, account, premium) : _renderVip(premium)}
        {_displayAccountSubscriptionBadge(item, account)}
        {ALL_LIVE_TYPE.includes(type) && <LiveBadge content={item} />}
      </div>
    );
  });

  const _renderTopContent = useCallback(topIndex => {
    if (topIndex <= 9 && topIndex >= 0) {
      return (
        <div className="ribbonTicketTopContent">
          <img src={require(`./../../../assets/img/top${topIndex + 1}.png`)} />
        </div>
      );
    }

    return null;
  });

  const _renderLazyLoad = useCallback(src => {
    return <LazyLoad key={src} className="srcImg" src={src} alt={src} threshold={300}></LazyLoad>;
  });

  const _renderEpName = useCallback(() => {
    const tenantConfig = getTenantConfig(getConfigByKey('tenant_slug'));
    const { t, index, epNameVisible, item } = props;

    if (tenantConfig && tenantConfig.RIBBON_RENDER_EP_NAME && epNameVisible) {
      return (
        <div
          className="episode-name"
          style={{
            color: '#959596',
          }}
        >{`${t('video.eps')} ${item.episode}`}</div>
      );
    }
  });

  const {
    linkTo,
    item,
    ribbon,
    index,
    displayTypeStr,
    contentType,
    type,
    dpUpdateRibbonTarget,
    remove,
    trailer,
    paidContents,
    account,
    handleClick,
    imgDefault,
    sourceURL,
  } = props;
  const srcImg = sourceURL[displayTypeStr] || sourceURL.thumbnail || imgDefault;
  const tenantConfig = getTenantConfig(getConfigByKey('tenant_slug'));
  // const timePaidContents = getTimePaidContent(paidContents, item.id);
  return (
    <Ribbons
      className={classnames(
        'ribbonItemContainer',
        // tenantConfig && tenantConfig.POSTER_SIZE,
        `${display[`DISPLAY_${ribbon ? ribbon.type : 1}`]}`,
      )}
      data-testid={`ribbon-detail-${index}`}
      style={{
        position: 'relative',
      }}
      key={srcImg}
    >
      <div className={classnames('imageWrapper', displayTypeStr)}>
        {item.is_new_release && (
          <DivStyle
            className={`ribbonNewRelease ${(type === display.SHOW_TYPE || type === display.EPISODE_TYPE) && 'ribbonNewRelease--epg'}`}
            theme={{
              main: 'Episode',
            }}
          >
            <p>New</p>
          </DivStyle>
        )}

        <Link
          to={() => {
            if (linkTo) {
              return {
                pathname: linkTo,
                state: {
                  type: item.type,
                },
                search: trailer && `?trailer=${item.slug}`,
              };
            }
          }}
          onClick={event => {
            if (linkTo) {
              return;
            }

            if (handleClick) {
              return handleClick();
            }

            if (!ribbon) {
              return;
            }

            pushAnalytics('select_content', {
              content_type: contentType,
              item_id: item.slug,
              widget: ribbon.slug,
              order: index + 1,
            });
            dpUpdateRibbonTarget(ribbon.id);
          }}
        >
          {_renderLazyLoad(srcImg)}
          {_renderTopContent(item.top_index)}
          {_renderYoutube()}
          {_renderTicket()}
        </Link>
        {remove ? (
          <Button className="remove" onClick={e => _remove(e, index)}>
            <i
              className="fa fa-trash"
              style={{
                color: '#fff',
                fontSize: 14,
                paddingRight: '0.5rem',
              }}
            />
            Remove
          </Button>
        ) : null}
      </div>
      {_renderEpName()}

      {!remove && <FooterContent {...props} />}
    </Ribbons>
  );
};

export const DivStyle = styled.div`
  &.ribbonNewRelease {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 8rem;
    height: 4rem;
    background: #00b2ff;
    margin-left: -1.75rem;
    transform: rotate(333deg);
    margin-top: -2rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    left: -1.25rem;
    color: #fff;
    font-weight: 600;
    p {
      margin: 0 0 5px;
    }
    &--epg {
      &::before {
        content: '${props => props.theme.main}';
        position: absolute;
        bottom: -0.35rem;
        width: 45%;
        font-size: 0.5625rem;
        height: auto;
        background: #fff;
        right: 0;
        transform: translateX(-23%);
        color: #2574d4;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const mapStateToProps = state => ({
  account: state.auth.account,
  paidContents: state.auth.paidContents,
  auth: state.auth,
});

const mapDispatchToProps = {
  dpUpdateRibbonTarget: id => updateRibbonTarget(id),
  dpSetOpenRequestSignIn: value => setOpenRequestSignIn(value),
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Contents));
