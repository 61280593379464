import React from 'react';
import { MinimumTierBadge } from './MinimumTierBadge';
import { useSelector } from 'react-redux';
import { accountSelector } from '@/shared/features/auth/reducers';
import { getSubscription } from '@/core/method/accountMethod';

export const AccountSubscriptionBadge = ({ title = 'Pro' }) => {
  const account = useSelector(accountSelector);
  const subscription = getSubscription.call(account);
  const { sub_tier = {} } = subscription ?? {};
  const { privileged_badge_name, show_account_subscription_badge_for_watchable_content } = sub_tier;
  const invisible =
    !subscription ||
    !show_account_subscription_badge_for_watchable_content ||
    !privileged_badge_name;
  if (invisible) {
    return null;
  }

  return <MinimumTierBadge title={privileged_badge_name ?? title} isPrivilege />;
};
