import clsx from 'clsx';
import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import { twMerge } from 'tailwind-merge';

export const MinimumTierBadge = props => {
  const { title, isPrivilege, hasBackground = true, className = '', bold = false } = props;

  return (
    <div
      className={twMerge(
        clsx(
          'minimum-titer-badge',
          'relative flex shrink-0 items-center justify-center gap-x-[3px] rounded-[5px]  py-[1px] pl-[5px] pr-[7px] text-[10px] font-semibold text-[#e8b600]',
          {
            'bg-[rgba(0,0,0)]': hasBackground,
            'bg-[#2ba13a] text-[#ffffff]': isPrivilege,
            'font-bold': bold,
          },
        ),
        className,
      )}
    >
      <StarIcon className="text-[120%]" />
      {/* <i  aria-hidden="true" /> */}
      <span className="uppercase">{title}</span>
    </div>
  );
};

export default MinimumTierBadge;
