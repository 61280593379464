import * as actions from './actions';
import * as appAPIs from '@/api/appAPIs';
import * as homePageApi from '@/api/homePageApi';

export {
  // getPage,
  getRibbon,
  // getPageMenu,
  getDetail,
  getSeason,
  refreshPage,
  getRelate,
  getEpg,
  updateRibbonTarget,
  updateStatusRemove,
};

function getRibbon(id, page) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_ribbon(id, page)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getDetail(slug) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_detail(slug)
        .then(response => {
          dispatch(actions.getDetail(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getSeason(seasonId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_season(seasonId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function refreshPage(isRefreshPage) {
  return dispatch => {
    dispatch(actions.refreshPage(isRefreshPage));
  };
}

function getRelate(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_relate(id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getEpg(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_epg(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateRibbonTarget(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (id) {
        dispatch(actions.updateRibbonTarget(id));
      }
      resolve();
    });
  };
}

function updateStatusRemove(data) {
  return dispatch => {
    dispatch(actions.updateStatusRemove(data));
  };
}

export function getStaticImages() {
  return dispatch =>
    new Promise((resolve, reject) => {
      homePageApi
        .getStaticImages()
        .then(response => {
          dispatch(
            actions.setExtraBanners(
              response.map(banner => ({
                images: banner,
              })),
            ),
          );
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
}
